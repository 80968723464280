import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { TestCloudFunction } from "../../components/TestCloudFunctions";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "save-file-to-s3"
    }}>{`Save File to S3`}</h1>
    <p>{`As you saw in the last exercise, taking screenshots is a slow process. You wouldn't want to do that every time a user wants to see your picture.`}</p>
    <p>{`And API Gateway isn't the happiest about serving images. You'll get high bandwidth bills.`}</p>
    <p>{`A better solution is to store your screenshot to S3 - a service made specifically for static file storage.`}</p>
    <h2 {...{
      "id": "exercise"
    }}>{`Exercise`}</h2>
    <p>{`Copy your solution from exercise-4 to exercise-5, or start from `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/tree/main/exercise-5"
      }}>{`mine in the repository`}</a>{`.`}</p>
    <p>{`Create a new YAML file to specify a new S3 bucket. This is where infrastructure as code shines.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# s3.yml
Resources:
  ScreenshotsBucket:
    Type: AWS::S3::Bucket
`}</code></pre>
    <p>{`Then include this file as a resource in your serverless config. And install the `}<inlineCode parentName="p">{`serverless-resources-env`}</inlineCode>{` plugin.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml
resources:
  - \${file(s3.yml)}

plugins:
  - serverless-resources-env
`}</code></pre>
    <p>{`You can now access your new S3 bucket's name as `}<inlineCode parentName="p">{`process.env.CF_ScreenshotsBucket`}</inlineCode></p>
    <p>{`You'll need to grant access to this bucket from your Lambda .`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml

provider:
  iamRoleStatements:
    - Effect: "Allow"
      Action:
        - "s3:*"
      Resource: "arn:aws:s3:::*/*"
`}</code></pre>
    <p>{`This grants full permissions on all S3 buckets in your account. You can limit to a specific bucket and specific permissions with tricky incantations. The biggest pain point of AWS if you ask me.`}</p>
    <p>{`To upload your screenshot to S3, use code like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// dont forget to import util and fs
const readFile = util.promisify(fs.readFile)
const buffer = await readFile(path)

// aws comes from importing aws-sdk
const s3 = new aws.S3({
  apiVersion: "2006-03-01",
})

const { Location } = await s3
  .upload({
    Bucket: process.env.CF_ScreenshotsBucket,
    Key: "<filename>.png",
    Body: buffer,
    ACL: "public-read", // grant public read access
  })
  .promise()
`}</code></pre>
    <p>{`Return the Location from your function.`}</p>
    <TestCloudFunction serviceName="serverless" urlPlaceholder="<domain>/take-screenshot" jsonPlaceholder="" mdxType="TestCloudFunction" />
    <h2 {...{
      "id": "solution"
    }}>{`Solution`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/tree/exercise-5-solution"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/tree/exercise-5-solution`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      